<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick">
      <div slot="pagePosition"><span class="color-primary">{{ stock }}</span></div>
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      title="新增库存管理"
      ref="lzDialog">
      <lz-form
        @change-select="handleChangeSelect"
        :form="form"
        :col="3"
        ref="lzForm">
      </lz-form>
      <template v-for="(element, index) in list">
        <lz-card
          :key="index"
          class="mt-20"
          :title="`库存${index + 1}`">
          <el-button slot="right" type="danger" @click="list.splice(index, 1)">删除</el-button>
          <lz-form
            :form="element"
            :col="3"
            ref="lzStockForm">
          </lz-form>
        </lz-card>
      </template>
    </lz-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      stock: '',
      search: [
        { name: '产品名称', type: 'text', fieldName: 'keyword', value: '' },
        // { name: '产品id', type: 'text', fieldName: 'goods_id', value: '' },
        {
          fieldName: 'spec_id',
          type: 'treeSelect',
          name: '规格',
          value: [],
          options: [],
          required: true,
          remote: true,
          multiple: true,
          props: {
            url: '/spec/list',
            value: 'id',
            label: 'name',
            params: { type: 1 },
          },
        },
        {
          fieldName: 'supplier_id',
          type: 'select',
          name: '供应商',
          value: '',
          options: [],
          remote: true,
          props: {
            url: '/supplier/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '入库', type: 'daterange', fieldName: 'begin_date||end_date', value: [], width: '300px' },
        {
          name: '操作人',
          type: 'select',
          fieldName: 'admin_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/admin/list',
            value: 'id',
            label: 'nick_name',
          },
        },
      ],
      config: {
        url: '/stock/list',
        setTableFormat: (data, response) => {
          this.stock = `当前：${response.curr_stock} / 总数：${response.total_stock}`;
          return data;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '产品名称', prop: 'goods_title' },
        {
          name: '规格名称',
          func: (ops) => ops.spec_name || '无规格',
        },
        { name: '供应商', prop: 'supplier_name' },
        { name: '备货价', prop: 'stocking_price' },
        { name: '税运', prop: 'tax_type_name' },
        { name: '备货数量', prop: 'stock' },
        {
          name: '入库时间',
          func: (ops) => ops.data_time.split(' ')[0],
        },
        { name: '备货人', prop: 'nick_name' },
        { name: '备注', prop: 'memo' },
      ],
      form: [
        {
          fieldName: 'goods_id',
          type: 'select',
          name: '产品名称',
          required: true,
          value: '',
          options: [],
          remoteMethod: (val) => {
            this.$baseHttp.get(`/goods/list?keyword=${val}`).then((response) => {
              const goods = this.form.find((r) => r.fieldName === 'goods_id');
              this.$set(goods, 'options', response.data.data.map((r) => ({
                ...r,
                label: r.title,
                value: r.id,
              })));
            });
          },
        },
        {
          fieldName: 'supplier_id',
          type: 'select',
          name: '供应商',
          required: true,
          value: '',
          options: [],
        },
        {
          fieldName: 'spec_id',
          type: 'select',
          name: '规格',
          required: true,
          value: '',
          options: [],
        },
      ],
      dialogBtns: [
        { key: 'create', name: '新增', type: 'primary' },
        { key: 'save', name: '保存', type: 'primary' },
      ],
      list: [],
    };
  },
  methods: {
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          if (this.list.length === 0) {
            this.$message.error('数据不能为空');
            return;
          }
          const data = [];
          for (let i = 0; i < this.list.length; i += 1) {
            const params = this.$refs.lzStockForm[i].getValue();
            if (params.spec_id === '-1') params.spec_id = '';
            if (!params) break;
            data.push(params);
          }
          if (data.length === 0) return;
          const response = await this.$baseHttp.post('/stock/save', {
            data,
          });
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        case 'create': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const data = [];
          for (let i = 0; i < this.list.length; i += 1) {
            data.push(this.$refs.lzStockForm[i].getValue());
          }
          const isRepeat = data.some((r) => r.goods_id === params.goods_id
            && r.supplier_id === params.supplier_id
            && r.spec_id === params.spec_id);
          if (isRepeat) {
            this.$message.error('存在相同参数');
            return;
          }
          const goods = this.form[0].options.find((r) => String(r.value) === params.goods_id);
          const supplier = this.form[1].options.find((r) => String(r.value) === params.supplier_id);
          const spec = this.form[2].options.find((r) => String(r.value) === params.spec_id);
          const price = supplier.stocking_price;
          this.list.push([
            { fieldName: 'goods_name', type: 'text', name: '商品名称', value: goods.label, required: true, disabled: true },
            { fieldName: 'goods_id', type: 'text', name: '商品ID', value: params.goods_id, required: true, hide: true },
            { fieldName: 'supplier_name', type: 'text', name: '供应商名称', value: supplier.label, required: true, disabled: true },
            { fieldName: 'supplier_id', type: 'text', name: '供应商ID', value: params.supplier_id, required: true, hide: true },
            { fieldName: 'spec_name', type: 'text', name: '规格名称', value: spec.label, required: true, disabled: true },
            { fieldName: 'spec_id', type: 'text', name: '规格ID', value: params.spec_id, required: true, hide: true },
            { fieldName: 'stocking_price', type: 'number', name: '备货价格', value: price, required: true },
            { fieldName: 'stock', type: 'number', name: '备货数', value: '', required: true, min: -999999999 },
            { fieldName: 'data_time', type: 'date', dateType: 'date', format: 'yyyy-MM-dd', name: '备货时间', value: dayjs().format('YYYY-MM-DD'), required: true },
            { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
          ]);
        }
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm() {
      this.$refs.lzDialog.dialogVisible = true;
      this.list = [];
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: {},
        });
      });
    },
    handleChangeSelect(element) {
      const ops = element.options.find((r) => String(r.value) === String(element.value));
      if (element.fieldName === 'goods_id') {
        this.form.filter((r) => ['supplier_id', 'spec_id'].includes(r.fieldName)).forEach((el) => {
          this.$set(el, 'value', '');
          this.$set(el, 'options', []);
          if (el.fieldName === 'supplier_id' && ops) {
            this.$set(el, 'options', ops.supplier.map((r) => ({
              ...r,
              label: r.supplier_name,
              value: r.supplier_id,
            })));
          }
        });
      }
      if (element.fieldName === 'supplier_id') {
        this.form.filter((r) => ['spec_id'].includes(r.fieldName)).forEach((el) => {
          this.$set(el, 'value', '');
          this.$set(el, 'options', []);
          if (ops && ops.spec_ids.length > 0) {
            this.$set(el, 'options', ops.spec_ids.map((r, index) => ({
              ...r,
              label: ops.spec_name[index],
              value: r,
            })));
          } else {
            this.$set(el, 'options', [
              { label: '无规格', value: '-1' },
            ]);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
